<template>
    <div class="aa-admin">
        <div class="aa-admin-titulo aa-text-h1 text-start px-1 mt-1">
            Administración
        <div class="line"></div>
    </div>
    <BotoneraAdmin />

    <div class="documentos mb-1 text-start mx-2">
        <h5>
            Desde aquí podrá descargar documentación referente al centro.
        </h5>

        <div class="aa-documentos" v-if="listadoDocumentos">
        
        <div class="documento m-1 p-1 text-start" v-for="documento in listadoDocumentos" :key="documento.id" @click="descargar(documento.documento)">
            <span class="d-block icon-download"> <img src="../../../assets/img/download.svg" /> </span>
            <span class="d-block txt-download">{{documento.nombre}}</span>
        </div>

    </div>

    </div>


    </div>
</template>

<script>

import BotoneraAdmin from "../components/Administracion/BotoneraAdmin.vue"
import useAdministracion from "../composables/useAdministracion";
export default {
    components: {BotoneraAdmin},
    setup() {
        const { isLoading, listadoDocumentos } = useAdministracion() 


        return {
            listadoDocumentos,
            isLoading,
            descargar: (url) => window.open(url)
        };
    }
}
</script>

<style lang="sass" scoped>
.aa-admin
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-titulo
        order: 1
        .line
            background-color: #86e4b9
            display: inline-block
            height: 20px
            left: 0
            position: absolute
            top: 37px
            width: 90%
            z-index: -1
    .documentos
        order: 3


.aa-documentos
        .documento
            display: flex
            flex-direction: row
            border-radius: 15px
            background-color: #f1f0f5
            align-items: center
            column-gap: 15px
            cursor: pointer
            .icon-download
                background-color: #ffffff
                padding: 0.4rem
                border-radius: 50%
                height: 30px
                width: 30px
                line-height: 44px
                img
                    width: 24px
            .txt-download
                font-size: 0.9rem
                font-weight: 600
</style>